input[type=button],
input[type=submit]
{
    border: 1px solid #181c6b;
    background-color: #3692d8;
    border-radius: 6px;
    padding: 8px 20px;
    color: #ffff;
    font-weight: bolder;
    transition: 0.8s;
}
input[type=button]:hover,
input[type=submit]:hover
{
    background-color: #181c6b;
}
.form
{
    padding-left: 20px;
}
.formrow
{
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}
.formrow label
{
    font-weight: bold;
}
.formrow input[type=text],
.formrow input[type=date]
{
    padding: 6px;
    border: 1px solid #181c6b;
    border-radius: 2px;
    width: 300px;
}
.formrow input[type=checkbox]
{
    margin-right: 10px;
    width: 14px;
    height: 14px;
}
.formrow select
{
    padding: 6px;
    border: 1px solid #181c6b;
    width: 300px;
}
.required
{
    color: #a20f0f;
    font-weight: bold;
}
.multiinput
{
    flex-direction: row;
}
.multiinput select
{
    width: 200px;
    margin-right: 8px;
}
.multiselect
{
    display: flex;
    flex-direction: column;
}
.checkboxcontainer
{
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 40px;
    padding-bottom: 8px;
}
.checkboxcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkbox
{
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid #181c6b;
    transition: 0.4s;
}
.checkboxcontainer:hover input ~ .checkbox
{
    background-color: #6bbfff;
}
.checkboxcontainer input:checked ~ .checkbox
{
    background-color: #3692d8;
}
.checkbox:after {
    content: "";
    position: absolute;
    display: none;
}
.checkboxcontainer input:checked ~ .checkbox:after {
    display: block;
}
.checkboxcontainer .checkbox::after {
    left: 7px;
    top: 3px;
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
@media screen and (max-width: 600px)
{
    .form
    {
        padding-left: 0px !important;
    }
    .formrow input[type=text],
    .formrow input[type=date]
    {
        width: 100% !important;
    }
    .formrow select
    {
        width: 100%;
    }
}