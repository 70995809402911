h1
{
    font-size: 16pt;
    font-weight: bolder;
    margin: 0px;
    padding: 0px;
    margin-bottom: 15px;
}
h2
{
    color: #3692d8;
    font-size: 14pt;
    font-weight: bolder;
    margin: 0px;
    padding: 0px;
    margin-bottom: 15px;
}
.main
{
    padding: 10px;
}
.appShroud
{
    position: fixed;
    z-index: 1000;
    left: 0; top: 0; right: 0; bottom: 0;
    background-color: rgba(10, 10, 10, 0.6);
}
.vcenter
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.signature
{
    border: 1px solid #181c6b;
    width: 400px;
    height: 150px;
}
.iFramePDF
{
    height:1300px;
    width:100%;
}