.header
{
    display: flex;
    height: 301px;
    justify-content: center;
    align-items: center;
    background-color: #3692d8;
}
.logo
{
    height: 221px;
}
.navbar
{
    display: flex;
    justify-content: center;
    background-color: #181c6b;
    height: 74px;
}
.navigation
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    font-weight: bold;
    margin: 0px;
}
.navigation a
{
    color: #ffffff;
    padding: 10px 35px 10px 35px;
    font-weight: 700;
    line-height: 1.3125;
    font-family: "Didact Gothic", Sans-serif;
    font-size: 26px;
    letter-spacing: 1px;
    margin: 0px;
}
.menutoggle
{
    color: #ffffff;
    position: absolute; 
    top: 20px;
    left: 20px;

}
.menutoggle::before
{
    font-family: "mobmenu";
    content: '\f008'
}